import React from "react"
import { Helmet } from "react-helmet-async"
import { graphql } from "gatsby"
import remark from 'remark';
import remarkHtml from 'remark-html';
import Layout from "./layout"
import Img from "gatsby-image"
import EmailSignUp from "../components/EmailSignUp"

const markdown = remark().use(remarkHtml);

function toHtml(value) {
  if (!value) {
    return value;
  }

  value = value.replace(/\n/g, '<br />');

  const htmlSurroundedInPTags = markdown.processSync(value).toString();

  // remove surrounding p tags
  return htmlSurroundedInPTags.substr(3, htmlSurroundedInPTags.length - 8);
}

function parseLyrics(text) {
  const recognizedHeadings = ['Chorus', 'Refrain', 'Coda', '(Optional Verse)'];

  const lines = text.split('\n');

  let verses = [];
  let repeatingPart;
  let endingPart;

  let verseNumber = 1;
  let label = undefined;
  let partLines = [];

  for (let i=0; i < lines.length; i++) {
    let line = lines[i];

    // Convert leading spaces to &nbsp;
    line = line.replace(/^\s+/, match => { return match.replace(/\s/g, '&nbsp;'); });

    if (!label) {
      // Is this a recognized heading, ignoring case?
      const recognizedHeadingIndex = recognizedHeadings.map(x => x.toLowerCase()).indexOf(line.toLowerCase());
      label = recognizedHeadingIndex !== -1 ? recognizedHeadings[recognizedHeadingIndex] : 'Verse';

      // If a non-verse heading, continue to the next loop iteration
      if (label !== 'Verse') {
        continue;
      }
    }

    if (line !== '') {
      partLines.push(`<span class="line">${line}</span>`);
    } else {
      const text = partLines.join('<br />');

      if (label === 'Verse') {
        verses.push({
          label: `${verseNumber}.`,
          text
        });
        verseNumber++;
      }

      if (label === '(Optional Verse)') {
        verses.push({
          label: `(${verseNumber}.)`,
          text
        });
        verseNumber++;
      }

      if (label === 'Chorus' || label === 'Refrain') {
        repeatingPart = {
          label,
          text
        };
      }

      if (label === 'Coda') {
        endingPart = {
          label,
          text
        };
      }

      label = undefined;
      partLines = [];
    }
  }

  // If there is a repeating part, like Refrain or Chorus,
  // note this at the end of verses 2 and following.
  if (repeatingPart) {
    for (let i=1; i < verses.length; i++) {
      verses[i].text += `<br /><i>[${repeatingPart.label}]</i>`; // e.g. [Refrain] or [Chorus]
    }
  }

  const parts = [
    verses[0],
    repeatingPart,
    ...verses.slice(1),
    endingPart
  ].filter(x => x !== undefined);

  return parts;
}

export default (props) => {
  const data = props.data;
  const frontmatter = data.markdownRemark.frontmatter;
  const fields = data.markdownRemark.fields;
  const v = {
    title: frontmatter.title,
    hasHeaderInfo: frontmatter.copyright || frontmatter.wordsAttribution || frontmatter.musicAttribution,
    wordsAttribution: toHtml(frontmatter.wordsAttribution),
    musicAttribution: toHtml(frontmatter.musicAttribution),
    copyright: toHtml(frontmatter.copyright),
    additionalInfo: data.markdownRemark.html,
    pdfHref: fields.pdfHref,
    audioHref: fields.audioHref,
    standardSlidesHref: fields.standardSlidesHref,
    widescreenSlidesHref: fields.widescreenSlidesHref,
    hasAudio: frontmatter.hasAudio
  };

  const lyricsSections = parseLyrics(frontmatter.text).map(part => {
    return <section key={part.label}>
      <h1>{part.label}</h1>
      <p dangerouslySetInnerHTML={{ __html: part.text }} />
    </section>
  });

  const canonicalUrl = `https://hymnsonline.org${props.pageContext.slug}`.toLowerCase(); // Netlify forces lowercase. Without all-lowercase, this will cause a redirect.
  const facebookPreviewImage = `https://hymnsonline.org${props.pageContext.slug}facebook-preview.png`;

  return (
    <Layout>
      <Helmet>
        <title>{v.title}</title>
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={v.title} />
        <meta property="og:description" content="Free PDF and PowerPoint files for download." />
        <meta property="og:image" content={facebookPreviewImage} />
      </Helmet>
      <div>
        <h1>{v.title}</h1>
        {
          v.hasHeaderInfo &&
          <header className="hymnInfo">
            <table><tbody>
              { v.wordsAttribution &&
              <tr>
                <td>Words:</td>
                <td dangerouslySetInnerHTML={{ __html: v.wordsAttribution }}></td>
              </tr>
              }
              { v.musicAttribution &&
              <tr>
                <td>Music:</td>
                <td dangerouslySetInnerHTML={{ __html: v.musicAttribution }}></td>
              </tr>
              }
              { v.copyright &&
              <tr>
                <td colSpan="2" className="copyright" dangerouslySetInnerHTML={{ __html: v.copyright }}></td>
              </tr>
              }
            </tbody></table>
          </header>
        }
        {
          v.hasAudio &&
          <aside>
            <audio controls src={v.audioHref} />
          </aside>
        }
        <aside>
          <h2>Sheet Music</h2>
          <div className="large-preview-box">
            <a href={v.pdfHref} data-goatcounter-click={v.pdfHref}>
              <div className="large-preview">
                <div>
                  <Img fluid={data.largePdfSample.childImageSharp.fluid} />
                </div>
                <div className="ribbon-overlay">PDF</div>
              </div>
            </a>
          </div>
          <h2>PowerPoint Slides</h2>
          <div className="powerpoint-items-box">
            <div>
              <a href={v.standardSlidesHref} data-goatcounter-click={v.standardSlidesHref}>
                <div className="powerpoint-item standard">
                  <div>
                    <Img fixed={data.standardSlidesSample.childImageSharp.fixed} />
                  </div>
                  <div className="ribbon-overlay">Standard</div>
                </div>
              </a>
            </div>
            <div>
              <a href={v.widescreenSlidesHref} data-goatcounter-click={v.widescreenSlidesHref}>
                <div className="powerpoint-item widescreen">
                  <div>
                    <Img fixed={data.widescreenSlidesSample.childImageSharp.fixed} />
                  </div>
                  <div className="ribbon-overlay">Widescreen</div>
                </div>
              </a>
            </div>
          </div>
        </aside>
        <article className="lyrics">
          <h2>Words</h2>
          {lyricsSections}
        </article>
        { v.additionalInfo &&
          <div dangerouslySetInnerHTML={{ __html: v.additionalInfo }} />
        }
      </div>

      <div style={{marginTop: `3em`}}>
        <EmailSignUp />
      </div>

      <div style={{marginTop: `3em`, fontSize: `0.8em`}}>
        <i>Notice a mistake? Having a problem? Let us know! Email <a href="mailto:feedback@hymnsonline.org">feedback@hymnsonline.org</a></i>
      </div>

    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $standardSlidesSamplePngRelativePath: String!, $widescreenSlidesSamplePngRelativePath: String!, $pdfSamplePngRelativePath: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title,
        wordsAttribution,
        musicAttribution,
        copyright,
        text,
        hasAudio
      },
      fields {
        pdfHref,
        standardSlidesHref,
        widescreenSlidesHref,
        audioHref
      }
    },
    standardSlidesSample: file(relativePath: { eq: $standardSlidesSamplePngRelativePath }) {
      childImageSharp {
        fixed(height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    widescreenSlidesSample: file(relativePath: { eq: $widescreenSlidesSamplePngRelativePath }) {
      childImageSharp {
        fixed(height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    pdfSample: file(relativePath: { eq: $pdfSamplePngRelativePath }) {
      childImageSharp {
        fixed(height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    largePdfSample: file(relativePath: { eq: $pdfSamplePngRelativePath }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
